<template>
  <div class="grid lg:grid-cols-2 md:grid-cols-1 my-5">
    <div>
      <h2 class="text-lg font-medium truncate mr-5 capitalize">Entradas de mercancía</h2>
    </div>
    <div class="flex justify-end">
      <Button v-if="$can('ut-pharmedicas.entrada-mercancia.access')" label="Agregar" icon="pi pi-plus" iconPos="left" @click="$router.push({name:'ut-pharmedicas.entrada-mercancia.access'})"/>
    </div>
  </div>
  <div class="grid grid-cols-1 my-5">
    <div class="box">
      <div class="mb-4 relative">
        <filtrosEntradaMercancia @updateFilters="updatedFilters"/>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1">
    <div class="box">
      <div class="p-5 relative">
        <listadoEntradas ref="listEntradas"/>
      </div>
    </div>
  </div>
</template>

<script>
import filtrosEntradaMercancia from '../components/filters.vue'
import listadoEntradas from '../components/list.vue'
import { ref } from 'vue'
export default {
  name: 'entradasMercanciaHome',
  components: {
    filtrosEntradaMercancia,
    listadoEntradas
  },
  setup () {
    const listEntradas = ref()

    const updatedFilters = (filtersEmit) => {
      listEntradas.value.filtersData = filtersEmit
      listEntradas.value.fetchEntradas()
    }
    return {
      listEntradas,
      updatedFilters
    }
  }
}
</script>

<style scoped>

</style>
