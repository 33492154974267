<template>
  <Dialog :header="titulo" v-model:visible="modalVisible" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '70vw'}" :maximizable="false" :modal="true">
    <div class="flex flex-col pb-5">
      <div class="pb-3">
        <span class="font-bold">Proveedor: </span>
        <span>{{infoEntradaDetail[0].CardCode}} - {{infoEntradaDetail[0].CardName}}</span>
      </div>
      <div>
        <span class="font-bold">No. Factura referencia: </span>
        <span>{{infoEntradaDetail[0].NumAtCard}}</span>
      </div>
    </div>

    <div>
      <DataTable :value="infoEntradaDetail"  class="p-datatable-sm text-xs" :scrollable="true" scrollHeight="500px">
        <Column field="ItemCode" header="Artículo" style="max-width: 110px; min-width: 110px"></Column>
        <Column field="ItemName" header="Descripción" style="max-width: 600px; min-width: 600px"></Column>
        <Column field="Cantidad" header="Cantidad" style="max-width: 100px; min-width:100px;"></Column>
        <Column header="Precio" style="max-width: 130px; min-width:130px;">
          <template #body="slotProps">
            <span>{{ $h.formatCurrency(slotProps.data.Precio, 2) }}</span>
          </template>
        </Column>
        <Column header="Total" style="max-width: 150px; min-width:150px">
          <template #body="slotProps">
            <span>{{ $h.formatCurrency(slotProps.data.Total, 2) }}</span>
          </template>
        </Column>
        <Column field="Bodega" header="Bodega" style="min-width: 200px;max-width: 200px;">
          <template #body="slotProps">
            <span>{{slotProps.data.WhsCode}} - {{slotProps.data.WhsName}}</span>
          </template>
        </Column>
      </DataTable>
    </div>
  </Dialog>

</template>

<script>
import { ref } from 'vue'
export default {
  name: 'modalDetalleEntrada',
  setup () {
    // variables
    const modalVisible = ref(false)
    const infoEntradaDetail = ref([])
    const titulo = ref('')
    // methods
    const openModal = (dataToShow) => {
      modalVisible.value = true
      infoEntradaDetail.value = dataToShow
      if (infoEntradaDetail.value.length > 0) {
        titulo.value = `Detalles de entrada No. ${infoEntradaDetail.value[0].DocNum}`
      }
    }
    const closeMaximizable = () => {
      modalVisible.value = false
    }
    return {
      modalVisible,
      infoEntradaDetail,
      titulo,
      closeMaximizable,
      openModal
    }
  }
}
</script>

<style scoped>

</style>
