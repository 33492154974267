<template>
  <div class="grid grid-rows-1">
    <div class="grid lg:grid-cols-3 md:grid-cols-2">
      <div class="flex  m-4 flex-col">
        <label>Fecha Inicial</label>
        <Calendar v-model="filters.fechaInicial" dateFormat="yy-mm-dd" :showIcon="true"/>
      </div>
      <div class="flex  m-4 flex-col">
        <label>Fecha Final</label>
        <Calendar v-model="filters.fechaFinal" dateFormat="yy-mm-dd" :showIcon="true"/>
      </div>
      <div class="flex  m-4 flex-col">
        <label>No. Documento</label>
        <InputText type="text" v-model="filters.DocNum"  placeholder="No. Documento"/>
      </div>
    </div>
  </div>
  <div class="grid grid-rows-1 pt-3">
    <div class="grid grid-cols-1 ">
      <div class="flex justify-end align-end">
        <Button label="Buscar" icon="pi pi-search" iconPos="left" @click="search" class="p-button-success mr-3"/>
        <Button label="Limpiar" icon="pi pi-times" iconPos="left" @click="resetFilters" class="p-button-danger mr-3"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'filtersEntradasMercancia',
  emits: ['updateFilters'],
  setup (props, { emit }) {
    const filters = ref({
      fechaInicial: '',
      fechaFinal: '',
      DocNum: ''
    })
    const search = () => {
      emit('updateFilters', filters.value)
    }
    const resetFilters = () => {
      filters.value = {
        fechaInicial: '',
        fechaFinal: '',
        DocNum: ''
      }
      emit('updateFilters', filters.value)
    }
    return {
      filters,
      resetFilters,
      search
    }
  }
}
</script>

<style scoped>

</style>
